<script setup lang="ts">
import { useI18n, Translation } from "vue-i18n"
import { AcceptConditionsProps } from "./AcceptConditions.props"

const props = withDefaults(defineProps<AcceptConditionsProps>(), {
  type: "default",
  classes: ""
})

const { t } = useI18n()
</script>

<template id="accept">
  <Translation
    :keypath="`acceptConditions.${type}.text`"
    tag="p"
    :class="`mouse markdown__link-hover-green-main block text-start ${classes}`"
  >
    <template v-slot:terms>
      <AppLink :to="t('acceptConditions.links.terms')">
        <span
          v-if="$te(`acceptConditions.${type}.terms`)"
          v-html="t(`acceptConditions.${type}.terms`)"
        ></span>
        <span v-else v-html="t('acceptConditions.default.terms')"></span>
      </AppLink>
    </template>
    <template v-slot:privacy>
      <AppLink :to="t('acceptConditions.links.privacy')">
        <span
          v-if="$te(`acceptConditions.${type}.privacy`)"
          v-html="t(`acceptConditions.${type}.privacy`)"
        ></span>
        <span v-else v-html="t('acceptConditions.default.privacy')"></span>
      </AppLink>
    </template>
    <template v-slot:cookies>
      <AppLink :to="t('acceptConditions.links.cookies')">
        <span
          v-if="$te(`acceptConditions.${type}.cookies`)"
          v-html="t(`acceptConditions.${type}.cookies`)"
        ></span>
        <span v-else v-html="t('acceptConditions.default.cookies')"></span>
      </AppLink>
    </template>
  </Translation>
</template>
